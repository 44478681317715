<template>
  <c-box w="full">
    <Breadcrumb :items="breadcrumbItems" />
    <hr v-chakra mt="20px" />

    <c-flex mt="20px">
      <c-text flex="1" font-size="3xl">Detail Glossary</c-text>
      <c-button
        v-if="glossary"
        type="button"
        variant="solid"
        variant-color="red"
        rounded="full"
        @click.prevent="isDeleting = true"
      >
        <c-image :src="require('@/assets/icon-trash.svg')" />
        <c-text ml="5px" font-weight="500" font-size="16px">Hapus</c-text>
      </c-button>
      <c-button
        v-if="glossary"
        type="button"
        variant="solid"
        variant-color="brand"
        color="white"
        rounded="full"
        ml="10px"
        as="router-link"
        :to="{
          name: 'superadmin.glossary-edit',
          params: { glossaryId: glossary.id },
        }"
      >
        <c-image :src="require('@/assets/icon-pencil.svg')" />
        <c-text ml="5px" font-weight="500" font-size="16px">Edit</c-text>
      </c-button>
    </c-flex>

    <c-box w="full" mt="30px">
      <c-box v-for="(item, idx) in dataGlossary" :key="item.label">
        <Detail
          v-if="!item.isGambar"
          :label="item.label"
          :value="item.value"
          v-chakra
          :mt="idx === 0 ? 0 : '10px'"
        />
        <c-box
          v-if="item.value != null && item.isGambar"
          :mt="idx === 0 ? 0 : '10px'"
        >
          <c-text color="brand.900">{{ item.label }}</c-text>
          <img
            :src="item.value"
            :alt="item.label"
            v-chakra
            h="230px"
            w="230px"
            rounded="lg"
            object-fit="contain"
          />
        </c-box>
      </c-box>
    </c-box>
    <Portal to="main" v-if="isDeleting">
      <DeleteConfirm
        :is-open="isDeleting"
        :content="`Anda yakin ingin menghapus glossary ini?`"
        @on-delete="deleteGlossary($event)"
      />
    </Portal>
  </c-box>
</template>

<script>
import Breadcrumb from "@/components/breadcrumb.vue";
import Detail from "./detail-text.vue";
import equal from "fast-deep-equal";
import DeleteConfirm from "@/views/admin/delete-confirm.vue";

export default {
  name: "SUGlossaryDetail",
  components: { DeleteConfirm, Breadcrumb, Detail },
  data() {
    return {
      glossary: null,
      isDeleting: false,
    };
  },
  computed: {
    breadcrumbItems() {
      return [
        {
          label: "Manajemen Glossary",
          href: this.$router.resolve({ name: "superadmin.glossary" }).href,
        },
        {
          label: "Detail Glossary",
          isCurrent: true,
        },
      ];
    },
    glossaryId() {
      return this.$route.params.glossaryId;
    },
    dataGlossary() {
      let g = this.glossary;
      return [
        {
          label: "Nama Makanan",
          value: g?.ingredientName,
        },
        {
          label: "Ukuran",
          value: g == null ? null : `${g?.dose} ${g?.doseUnit}`,
        },
        {
          label: "Golongan Makanan",
          value: g?.foodGroup,
        },
        {
          label: "Nama Bahan Dasar Makanan",
          value: g?.foodIngredient,
        },
        {
          label: "Gambar dan Caption",
          value: g?.photoUrl,
          isGambar: true,
        },
        {
          label: "Kalori",
          value: g?.calories,
        },
        {
          label: "Protein",
          value: g?.protein,
        },
        {
          label: "Lemak",
          value: g?.fat,
        },
        {
          label: "Karbohidrat",
          value: g?.carbohydrate,
        },
      ];
    },
  },
  watch: {
    glossaryId: {
      immediate: true,
      async handler(glossaryId, old) {
        if (equal(glossaryId, old)) return;

        this.glossary = await this.$store.dispatch(
          "suGlossary/detail",
          glossaryId
        );
      },
    },
  },
  methods: {
    async deleteGlossary(isDelete) {
      // console.log("@delete", isDelete);
      if (isDelete) {
        await this.$store
          .dispatch("suGlossary/delete", this.glossaryId)
          .then(() =>
            this.$router.replace({
              name: "superadmin.glossary",
            })
          );
        this.isDeleting = false;
      } else {
        this.isDeleting = false;
      }
    },
  },
};
</script>

<style scoped></style>
